import React, { useContext, useEffect /* useState */ } from 'react';
import t from '../../../../../utils/translate';
import { ButtonContainer, MetricsContainer, MetricsContainerHeader, TitlesContainer, CardsContainer } from './style';
import { Typography, colors, Button } from '@cencosud-ds/bigbang';
import { Barchart, Person, Cart } from './components/icons';
import Card from './components/card';
import { useHistory } from 'react-router-dom';
import { getSellerReportConversion, getSellerReportSales, getSellerReportViews } from 'api/metrics';
import SessionContext from 'api/session/context';
import { formartNumberPoint } from 'utils/patterns';
import { ReportViews, SalesInfo, SellerConvertionResponse } from 'api/metrics/interface';
import { useApi } from './hooks/useApi';
// import { trackGTMEventAction } from 'utils/analytics';
// import Dialog from 'components/Dialog/Dialog';
// import whatsapp from 'assets/img/whatsapp.png';
// import catcyber from 'assets/img/gatitocyber.png';
// import sellersShowModal from 'assets/sellers.json';

const Metrics: React.FC<{}> = () => {
  const history = useHistory();

  // const firstTimeTour = localStorage.getItem('firstTimeTour');

  const {
    auth: { jwt, sellerId },
  } = useContext(SessionContext);

  const { data: convertion, isLoading: sellerConvertionLoading, execute: execConvertion } = useApi<
    SellerConvertionResponse,
    { jwt: string; sellerId: string }
  >(getSellerReportConversion);
  const { data: reportSalesResponse = [], isLoading: reportSalesLoading, execute: execReportsViews } = useApi<
    ReportViews[],
    { jwt: string; sellerId: string }
  >(getSellerReportViews);
  const { data: average, isLoading: averageLoading, execute: execAverage } = useApi<SalesInfo, { jwt: string }>(
    getSellerReportSales,
  );

  // const [open, setOpen] = useState(false);
  // const [openExclusive, setOpenExclusive] = useState(false);
  // const [openWhatsappModal, setOpenWhatsappModal] = useState(true);

  useEffect(() => {
    execConvertion({ jwt, sellerId });
    execReportsViews({ jwt, sellerId });
    execAverage({ jwt });
  }, []);

  useEffect(() => {
    const sellerLocal = localStorage.getItem('sellerlocalmodalcyber');
    if (sellerLocal !== sellerId) {
      // setOpen(true);
      /* const exists = sellersShowModal.find(item => sellerId === item);
      if (!exists) {
        setOpenExclusive(true);
      } else {
      } */
    }
  }, [sellerId]);

  const onClick = () => {
    return history.push(`/reports/metrics`);
  };

  return (
    <MetricsContainer>
      <MetricsContainerHeader>
        <TitlesContainer>
          <Typography color={colors.primary[500]} propsElement={{}} typeElement="subtitle1" weight="bold">
            {t('metrics_home_title')}
          </Typography>
          <Typography color={'#777777'} propsElement={{}} typeElement="body2" weight="semiBold">
            {t('metrics_home_subtitle')}
          </Typography>
        </TitlesContainer>
        <ButtonContainer>
          <Button type="button" type-button="secondary" variant-button="s" onClick={onClick}>
            {t('metrics_home_button_title')}
          </Button>
        </ButtonContainer>
      </MetricsContainerHeader>
      <CardsContainer>
        <Card
          showData={true}
          title={t('metrics_home_card1_title')}
          tooltipMessage={t('metrics_home_card1_tooltip')}
          currency={''}
          isPercentage={true}
          data={convertion ? convertion.convertion.value[0].result : ''}
          icon={<Barchart />}
          isLoading={sellerConvertionLoading}
          onClick={onClick}
        />
        <Card
          showData={true}
          title={t('metrics_home_card2_title')}
          tooltipMessage={t('metrics_home_card2_tooltip')}
          currency={''}
          isPercentage={false}
          data={
            reportSalesResponse.length
              ? reportSalesResponse
                  .reduce((a, o) => {
                    return a + Number(o.pdp_views);
                  }, 0)
                  .toString()
              : ''
          }
          icon={<Person />}
          isLoading={reportSalesLoading}
          onClick={onClick}
        />
        <Card
          showData={true}
          title={t('metrics_home_card3_title')}
          tooltipMessage={t('metrics_home_card3_tooltip')}
          currency={'$'}
          isPercentage={false}
          data={average ? formartNumberPoint(average.netSales) : ''}
          icon={<Cart />}
          isLoading={averageLoading}
          onClick={onClick}
        />
      </CardsContainer>
      {/* {firstTimeTour === '1' && (
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
            localStorage.setItem('sellerlocalmodalcyber', sellerId);
          }}
          maxWidth="sm"
        >
          <h3 style={{ color: '#166DC2', fontSize: 24, textAlign: 'center', fontWeight: '700', margin: 0 }}>
            <b>¡¡Se extendió Black!!</b>
          </h3>
          <br />
          <div style={{ color: '#323A44', fontSize: 14, textAlign: 'center' }}>
            <div>
              Nos complace informarte que extendimos la duración del Black Friday <br />
              en Paris hasta el <b>lunes 9 de diciembre,</b> por lo que te invitamos a seguir <br />
              participando con nosotros y aumentar tus ventas. <br />
              Para esto, te sugerimos <b> extender la fecha de vigencia de tus precios hasta el 9 de diciembre, </b>
              ya sea desde nuestro Seller Center o tu integrador, según corresponda.
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 10 }}>
            <Button
              variant-button="m"
              width="100%"
              onClick={() => {
                setOpen(false);
                localStorage.setItem('sellerlocalmodalcyber', sellerId);
              }}
            >
              {t('cyber_text_button')}
            </Button>
          </div>
        </Dialog>
      )} */}
      {/* {firstTimeTour === '1' && (
        <Dialog
          open={openExclusive}
          onClose={() => {
            setOpenExclusive(false);
            localStorage.setItem('sellerlocalmodalcyber', sellerId);
          }}
          maxWidth="sm"
        >
          <h3 style={{ color: '#166DC2', fontSize: 24, textAlign: 'center', fontWeight: '700', margin: 0 }}>
            <b>¡¡Se viene Black!!</b>
          </h3>
          <br />
          <div style={{ color: '#323A44', fontSize: 14, textAlign: 'center' }}>
            <div>Te recomendamos tener en consideración estas fechas de cara a este gran evento:</div>
            <ul
              style={{
                listStyle: 'none',
              }}
            >
              <li>
                {'• '}
                <span style={{ color: '#166DC2' }}>Duración:</span> <b>28 de noviembre a las 19:00 </b> hasta el{' '}
                <b>2 de diciembre</b>
              </li>
              <li>
                {'• '}
                <span style={{ color: '#166DC2' }}>Límite para crear/modificar productos:</span> <b>22-11-2024</b> a las{' '}
                <b>23:59</b> hrs
              </li>
            </ul>
            <p style={{ marginTop: 20 }}>
              Es muy importante que cumplas con estas fechas y horarios para evitar inconvenientes durante el evento.
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 10 }}>
            <Button
              variant-button="m"
              width="100%"
              onClick={() => {
                setOpenExclusive(false);
                localStorage.setItem('sellerlocalmodalcyber', sellerId);
              }}
            >
              {t('cyber_text_button')}
            </Button>
          </div>
        </Dialog>
      )} */}
    </MetricsContainer>
  );
};

export default Metrics;
