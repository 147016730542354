import React, { useState, useEffect, useContext } from 'react';
import { IconSquareButton, Icons } from '@cencosud-ds/bigbang/antiguo';
import styled from '@emotion/styled';
import { withRouter } from 'react-router';
import { debounce } from 'lodash';
import SessionContext from '../../api/session/context';
import { orderByParentsAndChildrens } from './filtersMenu';
import objectHasProperty from '../../utils/objectHasProperty';
import { helpCenterRoute } from '../../utils/routes';
import { trackGTMEventAction, trackGTMEventActionIdentifyUser } from '../../utils/analytics';
import { getServicesStatus } from '../../single-spa/getServicesStatus';
import TourGuide from 'views/pages/tourguide';
import NavigationItem from 'components/NavigationItem';
import translate from 'utils/translate';
import {
  CENTRY,
  MULTIVENDE,
  PIM,
  YUJU,
  STARKOMS,
  PRODUCTECA,
  ECOMEXPERTS,
  ECOMSUR,
  ASTROSELLING,
  RELBASE,
} from 'variables/sellerTypes';
import {
  SellerPublishedIntegrated,
  SellerPublishedPim,
  SellerNotPublished,
  SelleMultiCentryNoPublishedTour,
} from 'utils/tourguide';
import { updateStatusTourGuide } from 'api/tourguide';
import { SELLER, ADMIN } from '../../variables/roles';
import { LearningGuide } from 'views/pages/learningGuide';
import getIcon from './../../utils/getIcon';
import { ModalLevels } from '../Reputation';
import { LOCK } from 'variables/nivele';
import SquareButtonNew from './navigation';

const DEBOUNCE_TIMEOUT = 300;

const HELP_CENTER_URL = 'https://ayuda.marketplace.paris.cl';

const IconSquareButtonStyled = styled(IconSquareButton)`
  margin: 3px 3px 0em 0px;
  width: 100px;
  & .hc-icon {
    path {
      stroke: rgb(77, 77, 77) !important;
    }
  }
  &:hover .hc-icon {
    path {
      stroke: rgb(255, 255, 255) !important;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  overflow: auto;
  top: 0px;
  bottom: 0px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const WrapperButtonsAndSubButton = styled.div`
  display: flex;
  height: 100px;
  .sub-button {
    display: none;
  }
  :hover {
    .sub-button {
      display: block;
    }
  }
`;

function Navigation(props) {
  const { history } = props;
  const [routes, setRoutes] = useState(props.routes);
  const [enableTourGuide, setEnableTourGuide] = useState(false);
  const [isTourGuide, setIsTourGuide] = useState(false);
  const [componentLoad, setComponentLoad] = useState(false);
  const changeReguest = localStorage.getItem('_change_request');
  const firstTimeTour = localStorage.getItem('firstTimeTour');
  const {
    auth: {
      sellerType,
      sellerId,
      jwt,
      role,
      sellerName,
      sellerIsInPublishHistory,
      statusCompleteTourGuide,
      newReputationSeller,
    },
    terms,
  } = useContext(SessionContext);

  useEffect(() => {
    const routesOrderByParentsAndChildrens = orderByParentsAndChildrens(props.routes);
    setRoutes([...routesOrderByParentsAndChildrens, helpCenterRoute]);
  }, [props.routes]);

  useEffect(async () => {
    setTimeout(async () => {
      if (changeReguest === null || changeReguest === undefined || changeReguest.isEmpty()) {
        if (firstTimeTour === '0') {
          if (!Array.isArray(terms) || !terms.length) {
            if (
              [
                CENTRY,
                MULTIVENDE,
                PIM,
                YUJU,
                STARKOMS,
                PRODUCTECA,
                ECOMEXPERTS,
                ECOMSUR,
                ASTROSELLING,
                RELBASE,
              ].includes(sellerType) &&
              !statusCompleteTourGuide
            ) {
              setEnableTourGuide(true);
              setComponentLoad(true);
            }
          } else {
            if (
              [
                CENTRY,
                MULTIVENDE,
                PIM,
                YUJU,
                STARKOMS,
                PRODUCTECA,
                ECOMEXPERTS,
                ECOMSUR,
                ASTROSELLING,
                RELBASE,
              ].includes(sellerType) &&
              !statusCompleteTourGuide
            ) {
              setEnableTourGuide(false);
              setComponentLoad(false);
            }
          }
        }
      }
    }, 1000);
  }, [firstTimeTour]);

  const navigation = route => {
    if (objectHasProperty(route, 'blank') && route.blank) {
      return window.open(route.path, '_blank');
    }
    history.push(route.path);
  };

  const onLaunchTourGuide = () => {
    trackGTMEventActionTourGuide({
      action: 'Clic en botón Guia de uso',
      category: 'Interacciones Tour Guide',
      tag: sellerName,
    });
    setEnableTourGuide(!enableTourGuide);
    setComponentLoad(!enableTourGuide);
  };

  const onFinishTourGuide = async () => {
    setIsTourGuide(!isTourGuide);
    setEnableTourGuide(!enableTourGuide);
    setComponentLoad(!enableTourGuide);
    await updateStatusTourGuide(jwt, sellerId, 1);
  };

  window.onFinishTourGuide = onFinishTourGuide;

  const launchHelpCenter = () => {
    window.open(HELP_CENTER_URL, '_blank', 'noopener,noreferrer');
  };

  const getClickHandler = (route, navigation) => {
    return debounce(() => {
      if (route.path) {
        getServicesStatus({ dispatchEvent: true }).then(() => {
          //trackGTMEventAction({ action: route.text, category: 'Menú marketplace', tag: route.path });

          const isSpecialRoute = ['Logistics', 'Fulfillment'].includes(route.id);
          const action = isSpecialRoute ? translate(route.id) : route.text;
          const tag = route.path;
          trackGTMEventAction(
            {
              action,
              category: 'Menú marketplace',
              tag,
            },
            role === SELLER ? sellerId : '',
            role,
          );
          navigation(route);
        });
      }
    }, DEBOUNCE_TIMEOUT);
  };

  const handlerSubRoutes = (routeWithSubRoutes, navigation) => {
    const { subRoutes } = routeWithSubRoutes;
    const newrutes = [routeWithSubRoutes, ...subRoutes];
    return newrutes.map((route, index) => {
      if (route.fixedAction === 'tourGuideAction') {
        if (role === SELLER) {
          return (
            <NavigationItem icon="TourGuide" label={translate('Tour Guide Item Label')} onClick={onLaunchTourGuide} />
          );
        } else {
          return null;
        }
      }

      if (route.fixedAction === 'helpCenter') {
        return (
          <NavigationItem icon="HelpCenter" label={translate('Help Center Item Label')} onClick={launchHelpCenter} />
        );
      }

      if (route.id === 'Fulfillment') {
        return (
          <NavigationItem icon="FulfillmentIcon" label={route.text} onClick={getClickHandler(route, navigation)} />
        );
      }

      if (route.text === 'Rendimiento') {
        return (
          <NavigationItem icon="IconRendimiento" label={route.text} onClick={getClickHandler(route, navigation)} />
        );
      }

      if (route.text === 'Reputacion') {
        return <NavigationItem icon="IconReputacion" label={route.text} onClick={getClickHandler(route, navigation)} />;
      }

      return (
        <IconSquareButtonStyled
          key={`IconSquareButtonStyled-${index}`}
          onClick={getClickHandler(route, navigation)}
          text={route.text}
          {...route}
          Icon={getIcon(route.icon)}
          className={index !== 0 && 'sub-button'}
        />
      );
    });
  };

  const routesWithoutChildren = routes.filter(r => !objectHasProperty(r, 'parentId') || !r.parentId);

  const getTourGuideConfiguration = () => {
    if ([PIM].includes(sellerType) && !sellerIsInPublishHistory) {
      return SellerNotPublished;
    }
    if (
      [CENTRY, MULTIVENDE, YUJU, STARKOMS, PRODUCTECA, ECOMEXPERTS, ECOMSUR, RELBASE, ASTROSELLING].includes(
        sellerType,
      ) &&
      !sellerIsInPublishHistory
    ) {
      return SelleMultiCentryNoPublishedTour;
    }
    if (
      [CENTRY, MULTIVENDE, YUJU, STARKOMS, PRODUCTECA, ECOMEXPERTS, ECOMSUR, RELBASE, ASTROSELLING].includes(
        sellerType,
      ) &&
      sellerIsInPublishHistory
    ) {
      return SellerPublishedIntegrated;
    }
    if ([PIM].includes(sellerType) && sellerIsInPublishHistory) {
      return SellerPublishedPim;
    }

    return {};
  };

  const getTourSteps = () => {
    const { steps } = getTourGuideConfiguration();
    return steps;
  };

  const getRoutesWithoutChildren = (route, index) => {
    switch (route.text) {
      case 'Finanzas':
        return (
          <div id="Finances">
            <WrapperButtonsAndSubButton key={`WrapperButtonsAndSubButton-${index}`}>
              {route.subRoutes ? (
                handlerSubRoutes(route, navigation)
              ) : (
                <IconSquareButtonStyled
                  key={`IconSquareButtonStyled2-${index}`}
                  onClick={debounce(() => {
                    getServicesStatus({ dispatchEvent: true }).then(() => {
                      trackGTMEventAction({ action: route.text, category: 'Menú marketplace', tag: route.path });
                      navigation(route);
                    });
                  }, DEBOUNCE_TIMEOUT)}
                  text={route.text}
                  onFinish
                  {...route}
                  Icon={Icons[`${route.icon}`]}
                />
              )}
            </WrapperButtonsAndSubButton>
          </div>
        );
      case 'Métricas':
        return (
          <div id={route.id}>
            <WrapperButtonsAndSubButton key={`WrapperButtonsAndSubButton-${index}`}>
              {route.subRoutes ? (
                handlerSubRoutes(route, navigation)
              ) : (
                <SquareButtonNew
                  id={route.id}
                  icon={route.icon}
                  label={route.text}
                  onClick={debounce(() => {
                    getServicesStatus({ dispatchEvent: true }).then(() => {
                      trackGTMEventAction({ action: route.text, category: 'Menú marketplace', tag: route.path });
                      navigation(route);
                    });
                  }, DEBOUNCE_TIMEOUT)}
                  chip={route.chip}
                />
              )}
            </WrapperButtonsAndSubButton>
          </div>
        );
      case 'Campañas':
        if ([SELLER].includes(role) && sellerId !== `e604b55f-eddb-492e-af08-13ed1e27d3a6`) {
          return (
            <div id={route.id}>
              <WrapperButtonsAndSubButton key={`WrapperButtonsAndSubButton-${index}`}>
                {route.subRoutes ? (
                  handlerSubRoutes(route, navigation)
                ) : (
                  <SquareButtonNew
                    id={route.id}
                    icon={route.icon}
                    label={route.text}
                    onClick={debounce(() => {
                      getServicesStatus({ dispatchEvent: true }).then(() => {
                        trackGTMEventAction({ action: route.text, category: 'Menú marketplace', tag: route.path });
                        navigation(route);
                      });
                    }, DEBOUNCE_TIMEOUT)}
                    chip={route.chip}
                  />
                )}
              </WrapperButtonsAndSubButton>
            </div>
          );
        }
        return <></>;
      default:
        if (route.id === 'Reports') return;
        return (
          <WrapperButtonsAndSubButton key={`WrapperButtonsAndSubButton-${index}`}>
            {route.subRoutes ? (
              handlerSubRoutes(route, navigation)
            ) : (
              <IconSquareButtonStyled
                key={`IconSquareButtonStyled2-${index}`}
                onClick={debounce(() => {
                  getServicesStatus({ dispatchEvent: true }).then(() => {
                    trackGTMEventAction({ action: route.text, category: 'Menú marketplace', tag: route.path });
                    navigation(route);
                  });
                }, DEBOUNCE_TIMEOUT)}
                text={route.text}
                onFinish
                {...route}
                Icon={getIcon(route.icon)}
              />
            )}
          </WrapperButtonsAndSubButton>
        );
    }
  };

  return (
    <>
      <Container>
        {routesWithoutChildren.map((route, index) => {
          return getRoutesWithoutChildren(route, index);
        })}
        {componentLoad ? (
          <TourGuide
            enableTourGuide={enableTourGuide}
            steps={getTourSteps()}
            canSkip={true}
            onFinish={() => onFinishTourGuide()}
            setEnableTourGuide={setEnableTourGuide}
          />
        ) : null}
      </Container>
      {role === ADMIN && <LearningGuide />}
      {![LOCK].includes(newReputationSeller.reputationCategory) && (
        <ModalLevels INewReputationSeller={newReputationSeller.reputationCategory} />
      )}
    </>
  );
}

export default withRouter(Navigation);
